
import { record } from 'rrweb'
import { eventWithTime } from '@rrweb/types'
import { current_user } from '@/store/auth'
import { watch } from 'vue'

if (import.meta.env.PROD && import.meta.env.VITE_SCREEN_RECORDER_ENABLED === 'true') {    
   
    const session_id = crypto.randomUUID()
    let events: eventWithTime[] = []
    
    watch(current_user, async () => {
        if (!current_user.value) {
            return
        }
    
        const body = JSON.stringify({
            session_id,
            key: 'user',
            value: current_user.value
        })
    
        await fetch(`${import.meta.env.VITE_SCREEN_RECORDER_ENDPOINT}/api/v1/sessions/meta-data`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${import.meta.env.VITE_SCREEN_RECORDER_KEY}`
            },
            body
        })
    })
    
    let timer: number | null = null
    
   
    const save = async (body: string) => {
        const resp = await fetch(`${import.meta.env.VITE_SCREEN_RECORDER_ENDPOINT}/api/v1/events`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${import.meta.env.VITE_SCREEN_RECORDER_KEY}`
            },
            body
        })
        if (!resp.ok) {
            throw new Error("Não foi possível salvar")
        }
        return resp
    }
    const delay = (time: number) => new Promise((resolve) => {
        setTimeout(resolve, time * 1000)
    })
    const store = async () => {
        if (events.length === 0) {
            return setTimeout(store, 30000)
        }
        const body = JSON.stringify({ events, session_id })
        events = []
        let retry = 1
        do {
            try {                
                await save(body)
                break
            } catch (error) {
                retry++                
                await delay(10)
            }
        } while (retry <= 3)
        
        setTimeout(store, 30000)
    }
    
    record({
        emit(event, is_checkout) {
            events.push(event)
    
        },
        checkoutEveryNms: 300000
        
    })
    
    store()
}

